import { UserT } from "../../../../interfaces/User";
import React from "react";
import UserSearchCard from "./UserSearchCard/UserSearchCard";

type UserListProps = {
  title: string;
  users: Array<UserT>;
  onUserClick: (student: UserT) => void;
  selectedUsers?: Array<UserT>;
};

export const UserList = ({
  title,
  users,
  onUserClick,
  selectedUsers,
}: UserListProps) => {
  return (
    <div className="search-result-container">
      <div className="row align-items-center gap-2 mb-3">
        <h3 className="fs-20">{title}</h3>
        <hr className="m-0 rounded" style={{ flex: 1, borderWidth: 4 }} />
      </div>
      {users.map(
        (user) =>
          (!selectedUsers ||
            (selectedUsers &&
              selectedUsers.findIndex((e) => e.id === user.id) === -1)) && (
            <UserSearchCard
              user={user}
              onUserClick={onUserClick}
              key={user.id}
            />
          )
      )}
    </div>
  );
};
