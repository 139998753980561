import React, { useState } from "react";
import { UserT } from "../../../../../interfaces/User";
import AvatarImg from "../../../../../assets/images/avatar-default.png";

type UserSearchCardProps = {
  user: UserT;
  onUserClick: (user: UserT) => void;
};

const UserSearchCard = ({ user, onUserClick }: UserSearchCardProps) => {
  const [imgSrc, setImgSrc] = useState(
    "https://app.wellness-studio.mx/uploads/wellness-logo.png"
  );

  const handleError = () => {
    setImgSrc(AvatarImg);
  };

  return (
    <div
      className="card py-2 px-3 mb-2 d-flex flex-row align-items-center gap-2 "
      role="button"
      key={user.id}
      onClick={() => onUserClick(user)}
    >
      <img
        className="rounded-circle"
        style={{ width: "40px", height: "40px", objectFit: "cover" }}
        src={imgSrc}
        onError={handleError}
        alt={`${user.name} ${user.lastname}`}
      />
      <h2 className="fs-18">
        {user.name} {user.lastname}
      </h2>
    </div>
  );
};

export default UserSearchCard;
