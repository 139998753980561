import "./CustomLoader.scss";
import React from "react";
import WellnessLogo from "../../assets/images/logos/wellness-logo.png";

const CustomLoader = () => {
  return (
    <div className="custom-loader-component flex-grow-1 h-100 w-100 d-flex justify-content-center align-items-center">
      <img
        src={WellnessLogo}
        alt={"wellness studio"}
        style={{ aspectRatio: "16/9", width: 300, height: "auto" }}
      />
    </div>
  );
};

export default CustomLoader;
