import React, { useCallback, useEffect, useState } from "react";
import { UserList } from "../UserList/UserList";
import { useClassContext } from "../../../../context/ClassContext";
import { UserT } from "../../../../interfaces/User";
import { fetchAllStudents, searchUsers } from "../../../../services/Users";
import { addUserToClass } from "../../../../services/ClassesService";
import {
  notifyError,
  notifySuccess,
} from "../../../../services/ToastNotifications";

type AddNewUserProps = {
  onHide: () => void;
};

const AddNewUser = ({ onHide }: AddNewUserProps) => {
  const [selectedUsers, setSelectedUsers] = useState<Array<UserT>>([]);
  const [searchResults, setSearchResults] = useState<Array<UserT>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText, setDebouncedText] = useState<string>("");
  const { clase, getStudents } = useClassContext();

  const fetchSearchUser = useCallback(async (searchText: string) => {
    try {
      const res = await searchUsers(searchText);
      console.log("res", res);
      setSearchResults(res.data);
    } catch (error) {
      console.error("Error fetching search users", error);
      notifyError("Ha habido un error al buscar los alumnos.");
    }
  }, []);

  const handleSelection = (student: UserT) => {
    const tempArray = [...selectedUsers];

    const studentIndex = tempArray.findIndex((e) => e.id === student.id);

    if (studentIndex === -1) {
      tempArray.push(student);
    } else {
      tempArray.splice(studentIndex, 1);
    }

    setSelectedUsers(tempArray);
  };

  const handleAddUsers = async () => {
    await Promise.all(
      selectedUsers.map(async (user) => {
        await addUserToClass(clase!.id, user.id)
          .then((res) => {
            getStudents(clase!.id);
            onHide();
            notifySuccess("Alumna agregada a la clase con éxito");
          })
          .catch((error) => {
            notifyError(
              `${user.name} ${user.lastname}: ${error.response.data.message}`
            );
          });
      })
    );
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText.length >= 2) {
      fetchSearchUser(searchText);
    } else {
      setSearchResults([]);
    }
  }, [debouncedText]);

  return (
    <div className="add-new-user-main-container">
      <div className="user-selection-container">
        <div className="form-outline mb-4">
          <label className="form-label fw-bold" htmlFor="form2Example17">
            Alumno
          </label>
          <input
            type="text"
            id="form2Example17"
            className="form-control form-control-lg"
            value={searchText}
            placeholder="Nombre"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {selectedUsers.length > 0 && (
        <UserList
          title={"Usuarios seleccionados"}
          users={selectedUsers}
          onUserClick={handleSelection}
        />
      )}
      {debouncedText.length > 0 && searchResults.length > 0 && (
        <UserList
          title={"Resultados de la búsqueda"}
          users={searchResults}
          selectedUsers={selectedUsers}
          onUserClick={handleSelection}
        />
      )}
      <div className="row justify-content-between mt-4">
        <button className="btn btn-danger px-3" onClick={onHide}>
          Cancelar
        </button>
        <button className="btn btn-success px-3" onClick={handleAddUsers}>
          Agregar
        </button>
      </div>
    </div>
  );
};

export default AddNewUser;
